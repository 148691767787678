import React from "react"

import { DocumentDisplay } from "../../components/common"
import termsAndConditionsDocument from "./termsAndConditionsDocument"

const TermsAndConditions = () => {
  return (
    <div className="mb-4">
      <DocumentDisplay doc={termsAndConditionsDocument} />
    </div>
  )
}

export default TermsAndConditions
