export default [
  {
    type: "section",
    content: [
      {
        header1: "ALVYAPP END-USER LICENCE AGREEMENT (“EULA”)",
      },
      {
        header3:
          "THIS DOCUMENT IS A BINDING LEGAL AGREEMENT BETWEEN YOU AND BIT MORE MEDIA – IF YOU PROCEED TO USE THE APP YOU WILL BE DEEMED TO HAVE ACCEPTED ITS CONTENTS AND WILL BE BOUND BY ITS TERMS, SO PLEASE TAKE TIME TO READ THEM CAREFULLY AND IN FULL.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "IMPORTANT WORDS AND DEFINITIONS",
      },
      {
        header3:
          "In this EULA, the following words have the following meanings:",
      },
      {
        definitions: [
          {
            term: "Account",
            meaning:
              "means a user account which is individual to you, which is created for the purpose of becoming a User of the App by providing us with certain personal details such as your e-mail address, Facebook account or Google account.",
          },
          {
            term: "alvyApp",
            meaning:
              "means the app developed by Bit More Media called the alvyApp, version 1.0 or later, which may be made available as a downloadable mobile software application.",
          },
          {
            term: "Basic Access",
            meaning:
              "means your use of the Services by downloading the alvyApp to your Device without creating an Account or providing us with any account information or personal details.",
          },
          {
            term: "Bit More Media",
            meaning:
              "means Bit More Media Limited, a company registered in England and Wales, company number 09673233, with its registered address at 7 Three Rivers Business Park Felixstowe Road, Foxhall, Ipswich, England, IP10 0BF.",
          },
          {
            term: "Community Guidelines",
            meaning:
              "means the code of conduct which Users must follow when using the App and Services. ",
          },
          {
            term: "Content",
            meaning:
              "means any text, software, scripts, graphics, photos, sounds, music, videos, audio-visuals combinations, interactive features and other materials you may view on or access through the App, including, without limitation, Public Question Sets Personal Questions Sets, Premium Question Sets, Invitation Question Sets and User Content. ",
          },
          {
            term: "Data Protection Legislation",
            meaning:
              "means for such time as they are in force in England and Wales, all legislation which relates to the protection of individuals’ rights in their personal data and the protection of their privacy, including the DPA, GDPR, PECR and all such legislation as may supplement, amend or replace them from time to time.",
          },
          {
            term: "Device",
            meaning:
              "means a mobile telephone with internet functionality, or a computing device with internet functionality (such as a touchscreen ‘tablet’).",
          },
          {
            term: "DPA",
            meaning:
              "means the Data Protection Act 2018 and all subordinate legislation to it.",
          },
          { term: "GDPR", meaning: "means Regulation (EU) 2016/679." },
          {
            term: "Intellectual Property Rights",
            meaning:
              "means patents, trademarks, and service marks, rights in design, trade or business names or signs or domain names, copyrights (including without limitation rights in computer software, databases and websites), database rights, rights in confidential information (including without limitation know how and trade secrets), moral rights (and the benefit of any and all waivers thereof), rental and lending rights, topography rights (whether or not any of these are registered and including applications for registration of any such thing) and all rights or forms of protection of a similar nature or having equivalent or similar effect to any of these which may subsist at any time, whether in the past, present or future, anywhere in the world and all rights of action and goodwill arising at any time in relation thereto. ",
          },
          {
            term: "Invitation Function",
            meaning:
              "means a functionality of the App which enables Users to share or receive Personal Question Sets from other Users.",
          },
          {
            term: "Invitation Questions Sets",
            meaning:
              "means Personal Question Sets which are shared with other Users using the Invitation Function. ",
          },
          {
            term: "PECR",
            meaning:
              "means the Privacy and Electronic Communications (EC Directive) Regulations 2003.",
          },
          {
            term: "Personal Data",
            meaning:
              "has the meaning set out in the Data Protection Legislation.",
          },
          {
            term: "Personal Question Sets",
            meaning:
              "means question and answer sets which can be created by Users and are personal to that own User’s Account. ",
          },
          {
            term: "Premium Question Sets",
            meaning:
              "means question and answer sets which have premium status and which Users may be required to pay in order to access. ",
          },
          {
            term: "Privacy Policy",
            meaning:
              "means Bit More Media’s privacy policy supplied to Users via the App, as may be updated from time to time. ",
          },
          {
            term: "Public Question Sets",
            meaning:
              "means question and answer sets that are publicly available to all Users without charge.  ",
          },
          {
            term: "Services",
            meaning:
              "means your use of the App and the Content we provide to you through it.  ",
          },
          {
            term: "Specification",
            meaning:
              "means any technical specification of the App’s functionality or commercial details which may be made available via the App from time to time.",
          },
          {
            term: "User",
            meaning:
              "means an individual who downloads the App to use the Services (either via Basic Access or by creating an Account); and terms such as “Users” shall be interpreted accordingly.",
          },
          {
            term: "User Content",
            meaning:
              "means Content which a User contributes, submits, uploads, publishes or otherwise make available via the App, including without limitation any Personal Question Sets and Invitation Question Sets.   	",
          },
        ],
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        paragraph:
          "Where this EULA refers to “you” or “your” it means the individual accepting this agreement; where it refers to “us”, “our” or “we” it means Bit More Media.",
      },
      {
        header2: "Compatibility Notice",
      },
      {
        paragraph:
          "The App is designed to work on mobile phone devices with iOS operating systems 9 or later and Android operating systems 4 or later. The App is designed to function in portrait mode only and is not optimised for tablets.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "Agreed terms",
      },
      {
        header3: "1.	ACKNOWLEDGEMENTS",
      },
      {
        list: [
          "1.1.	Use of the App by you is governed by the terms of this EULA but may also be subject to any rules or policies applied by any appstore provider or operator from whose site you may download the App (such a site an “Appstore”, and such rules “Appstore Rules”).  If there is any conflict between the terms of this EULA and the Appstore Rules, the Appstore Rules will apply instead of these terms. ",
          "1.2.	We may change these terms at any time without notice to you. Any such changes shall take effect on the next occasion that you make use of the App.  Any such new terms may be displayed on-screen when you next use the App and you may be required to read and accept them in order to continue your use of the App.",
          "1.3.	The terms of this EULA apply to the App and to any updates or supplements to the App, unless such additions are provided pursuant to separate terms, in which case those terms shall apply. If any open-source software is included in the App or any Service, the terms of an open-source licence may override some of the terms of this EULA.",
          "1.4.	From time to time updates to the App may be issued through the Appstore, or we may automatically update the App and change the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Depending on the update, you may not be able to use the Services until you have downloaded, streamed or accepted the updates and accepted any new applicable terms.",
          "1.5.	You will be assumed to have obtained permission from the owners of any Devices that are controlled, but not owned, by you which you may use to access or use the App. You and they may be charged by your and their service providers for internet access on the Devices. You accept responsibility in accordance with the terms of this EULA for the use of the App on any such Device, whether or not it is owned by you.",
          "1.6.	The terms of our Privacy Policy are incorporated into this EULA by reference and apply to your use of the App. You can review our Privacy Policy at any time when using our App. You acknowledge that we will process your Personal Data on the basis set out in our Privacy Policy. You warrant that any Personal Data that you may provide to us is accurate and complete in all respects.",
          "1.7.	By using the App or any of the Services, you agree to us collecting and using technical information about the Devices you use the App on and related software, hardware and peripherals to improve our services. Where you are not the Device owner, you warrant that the Device owner accepts that we may collect technical information about that specific Device. ",
          "1.8.	You acknowledge and agree that internet transmissions are never completely private or secure and that any message or information you send using the App or any Service may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.",
          "1.9.	Any words following the terms including, include, in particular or for example or any similar phrase shall be construed as illustrative and shall not limit the generality of the related general words.",
        ],
      },
      {
        header3: "2.	USING OUR SERVICES",
      },
      {
        list: [
          "2.1.	In order to receive the Services, you must download the App on your Device. You may access the Services using our Basic Access functionality or you may create an Account. ",
          "2.2.	You agree that you will be solely responsible for all activity that occurs on your Device and if you do create an Account, you agree to maintain the security and secrecy of your Account username and password.",
          "2.3.	You confirm that the information you provide when creating your Account or otherwise using our Services is accurate and complete, and that you will update us via the App as and when your information changes. ",
          "2.4.	You must notify Bit More Media immediately of any breach of security or unauthorised use of our Services that you become aware of.  ",
          "2.5.	You must be at least 4 years of age to use the Services. However, by setting up an Account you confirm that you are at least 13 years of age. ",
          "2.6.	You accept and acknowledge that you may be required to pay in order to receive access to the Premium Question Sets. ",
        ],
      },
      {
        header3: "3.	LICENCE",
      },
      {
        list: [
          "3.1.	In consideration of you agreeing to abide by the terms of this EULA, which we accept as sufficient consideration, we grant you a revocable, non-transferable, non-exclusive licence to use the App and to receive the Services on your Devices, subject to these terms, the Privacy Policy, the Community Guidelines, any applicable Appstore Rules, and any other documents referred to hereunder. We reserve all other rights.",
          "3.2.	We may, for any reason, suspend, terminate or withdraw the licence at Clause 3.1 or your access to the App and Services, without notice, reason or liability to you.   ",
        ],
      },
      {
        header3: "4.	CONTENT",
      },
      {
        list: [
          "4.1.	As a User, you may access Content when using the Services. ",
          "4.2.	You acknowledge and accept that you may be exposed to Content, including another User’s User Content and Invitation Question Sets, that is factually inaccurate, offensive, indecent, or otherwise objectionable to you. ",
          "4.3.	You agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against Bit More Media with respect to any such Content, including, without limitation your reliance on: ",
          "(a)	any Invitation Question Sets sent to you by another User; and ",
          "(b)	any Public Questions Sets and Premium Question Sets.   	",
          "4.4.	You agree to only access Content solely as intended through and permitted by the normal functionality of the Service and as set out in this EULA or in any communication we send to you.",
          "4.5.	You agree to use the Invitation Function to share a Personal Question Set with no more than [insert number] of Users. ",
          "4.6.	Bit More Media may, but shall not be obligated to, review, monitor, remove, amend or correct Content, at its sole discretion and at any time and for any reason, without notice to you.",
        ],
      },
      {
        header3: "5.	YOUR USER CONTENT",
      },
      {
        list: [
          "5.1.	As a User, you may submit User Content. ",
          "5.2.	You agree that we shall be the owners of all Intellectual Property Rights in any User Content and that any such Intellectual Property Rights belong to us or shall be assigned or transferred to us immediately on creation.    ",
          "5.3.	To the extent that the assignment at Clause 5.2 is not valid or effective for any reason, you grant Bit More Media a worldwide, perpetual, irrevocable, transferable, royalty-free licence, with the right to sub-licence, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and distribution channels now known or hereafter devised without further notice to or consent from you, and without the requirement of payment to you or any other person or entity. ",
          "5.4.	You acknowledge and agree that any User Content you share with other Users are granted a worldwide, non-exclusive, royalty-free licence to access your Content through the Service, and to use reproduce, distribute, prepare derivative works of, display such User Content. You further understand that we have limited control over other User’s use of your User Content and accept that Bit More Media shall not be responsible for such use. ",
          "5.5.	You agree not to provide User Content that is inaccurate, defamatory, libellous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by Bit More Media in its sole discretion, whether or not such material may be protected by law.  Without limiting the generality of the foregoing, you understand and acknowledge that any Personal Question Sets you create or share with other Users using the Invitation Function are to the best of your knowledge accurate and up-to-date. ",
        ],
      },
      {
        header3: "6.	LINKS FROM THE ALVYAPP",
      },
      {
        list: [
          "6.1.	The Service may include hyperlinks to other web sites that are not owned or controlled by Bit More Media.  Bit More Media has no control and assumes no responsibility for, the content, privacy policies or practices of any third-party websites. ",
          "6.2.	You acknowledge and agree that Bit More Media is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources. ",
          "6.3.	You acknowledge and agree that Bit More Media is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such web sites or resources. ",
        ],
      },
      {
        header3: "7.	INTELLECTUAL PROPERTY",
      },
      {
        list: [
          "7.1.	All Intellectual Property Rights in the App and the Services throughout the world belong to us (or our licensors) and the rights in the App and the Services are licensed (not sold) to you. You have no Intellectual Property Rights in, or to, the App or the Services other than the right to use them in accordance with these terms.  ",
          "7.2.	Without prejudice to Clause 5.3, you accept and acknowledge that all Content on the Service is either owned by or licensed to Bit More Media and is subject to Intellectual Property Rights of Bit More Media or Bit More Media’s licensors. Any third-party trade or service marks present on Content are trade or service marks of their respective owners. Such Content may not be downloaded, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purpose whatsoever without the prior written consent of Bit More Media or, where applicable, Bit More Media’s licensors. Bit More Media and its licensors reserve all rights not expressly granted in and to their Content. ",
        ],
      },
      {
        header3: "8.	LICENCE RESTRICTIONS",
      },
      {
        paragraph:
          "Except as expressly set out in this EULA or as specifically permitted by any local law, you agree:",
      },
      {
        list: [
          "(a)	not to copy the App or the Services except where such copying is incidental to normal use of the App, or where it is necessary for the purpose of back-up or operational security;",
          "(b)	not to rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Services in any form, in whole or in part to any person without prior written consent from us; ",
          "(c)	not to translate, merge, adapt, vary or modify the whole or any part of the App or the Services nor permit the App or the Services or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Services on Devices as permitted in these terms;",
          "(d)	not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App or attempt to do any such thing except to the extent that (by virtue of section 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are essential for the purpose of achieving inter-operability of the App with another software program, and provided that the information obtained by you during such activities:",
          "(i)	is used only for the purpose of achieving inter-operability of the App with another software program;",
          "(ii)	is not disclosed or communicated without our prior written consent to any third party; ",
          "(iii)	is kept secure; and",
          "(iv)	is not used to create any software that is substantially similar to the App;",
          "(e)	not to provide or otherwise make available the App in whole or in part (including object and source code), in any form to any third party without prior written consent from Bit More Media; and",
          "(f)	to comply with all technology control or export laws and regulations that may apply to the technology used or supported by the App or Service.  	",
        ],
      },
      {
        paragraph: "Together such conditions the “Licence Restrictions”.",
      },
      {
        header3: "9.	ACCEPTABLE USE RESTRICTIONS",
      },
      {
        paragraph:
          "As a condition of being granted access to the App and the Services you agree:",
      },
      {
        list: [
          "(a)	to read and comply with the Community Guidelines; ",
          "(b)	not use the App or Service if you are under the age of 4 and not to create an Account if you are under the age of 13;",
          "(c)	not upload or submit any information or data to the App which is inaccurate, false or misleading; ",
          "(d)	not to (or attempt to) circumvent, disable or otherwise interfere with any security related features of the Service or features that enforce limitation on use of the Service or the Content;",
          "(e)	not to launch any automated system (including, without limitation, any robot, spider or offline reader) that accesses the Services in a manner that sends more request messages to Bit More Media servers in a given period of time than a human can reasonably produce in the same period by using a publicly available, standard web browser; ",
          "(f)	not transmit any material, including User Content, that is defamatory, offensive or otherwise objectionable in relation to your use of the App or any Service; ",
          "(g)	not use the App or Service in an unlawful manner, for any unlawful purpose, or in any manner inconsistent with this EULA, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the App, any Service or any operating system;",
          "(h)	not use the App or Service to offer, solicit, arrange, or engage in, any kind of activity or arrangement which is, or which would be unlawful;",
          "(i)	not use the App or Service (including the comments features of the Service) for the solicitation of business in the course of trade or in connection with a commercial enterprise; ",
          "(j)	not infringe our Intellectual Property Rights or those of any third party in relation to your use of the App or any Service (to the extent that such use is not licensed by this EULA);",
          "(k)	not use the App or Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other Users; and",
          "(l)	not collect, extract or harvest any information or data from the App, any Service or our systems or attempt to decipher any transmissions to or from the servers running any Service.",
        ],
      },
      {
        paragraph:
          "Together the conditions of use set out in this Clause 9 are referred to as the “Acceptable Use Restrictions”.",
      },
      {
        header3: "10.	WARRANTIES AND DISCLAIMERS ",
      },
      {
        list: [
          "10.1.	Although we make reasonable efforts to update the information provided by the App and the Service, we make no representations, warranties or guarantee, whether express or implied that such information is accurate, complete or up to date. You hereby agree that it is your responsibility to verify the accuracy of any Content and that should you rely on such Content for any reason or purpose you agree to release Bit More Media from all claims, demands, actions, liabilities, suits, whether they are informal or formal, in respect of such Content.  ",
          "10.2.	Without prejudice to Clause 10.3, nothing in this EULA shall affect any statutory rights that you are always entitled to as a consumer and that you cannot contractually agree to alter or waive.",
          "10.3.	To the extent permitted by law, implied warranties or terms that cannot be contractually altered or waived, shall not apply to this EULA in relation to software that is faulty or not as described where: ",
          "(a)	the defect or fault in the App or any Services, results from you having altered or modified the App; or ",
          "(b)	the defect or fault in the App or any Services, results from you having used the App or Services in breach of the terms of this EULA.",
          "10.4.	The Service is provided “as is” and “as available” and in particular, Bit More Media makes no warranty or representation that:",
          "(a)	Your use of the Service will meet your requirements;",
          "(b)	The Service will be available; ",
          "(c)	Your use of the Service will be reliable, uninterrupted, timely, secure of free from error; and",
          "(d)	Any defects in the operation or functionality of the App will be corrected. ",
          "10.5.	Save for as expressly set out in these terms, no implied warranties or other terms, including any implied term relating to satisfactory quality or fitness for purpose, shall apply to the App or its use by you.  ",
        ],
      },
      {
        header3: "11.	LIMITATION OF LIABILITY",
      },
      {
        list: [
          "11.1.	You acknowledge and accept that: ",
          "(a)	the App has not been developed to meet your individual requirements, and that it is therefore your responsibility to ensure that the facilities and functions of the App as described in the Specification meet your requirements.",
          "(b)	Bit More Media does not review, check or monitor User Content. Accordingly, you agree that Bit More Media shall not be liable or responsible for any such User Content. ",
          "(c)	Bit More Media cannot guarantee or control the accuracy of Content. Accordingly, you agree that Bit More Media shall not be liable or responsible for any such Content. ",
          "11.2.	We shall only be responsible for loss or damage you suffer that is a foreseeable result of our breach of this EULA up to the limit specified in Clause 11.5 and subject to clause 11.6, but we are not responsible for any unforeseeable loss or damage. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.",
          "11.3.	Notwithstanding Clause 11.2, we shall for: ",
          "(a)	any indirect or consequential loss or damage; or ",
          "(b)	any loss of profit, loss of sales or business, loss of contracts, loss of use or corruption of software, data or information or loss of business opportunity, whether such losses arise directly or indirectly, ",
          "incurred by you in connection with or arising from or under this EULA. ",
          "11.4.	Notwithstanding Clause 11.2, Bit More Media shall not under any circumstances be liable for any loss or damage you suffer as a result of: ",
          "(a)	any reliance placed by you on the completeness, accuracy or existence of any Content; ",
          "(b)	any reliance place by you on any communication, relationship or transaction between you and any advertiser or sponsor whose advertising appears on the Service;",
          "(c)	any changes which Bit More Media may make to the Service, or for any permanent or temporary cessation in the provision of the Service (or any features within the Service);",
          "(d)	the deletion of, corruption of, or failure to store, any Content and other communications data maintained or transmitted by or through your use of the Service;",
          "(e)	your failure to provide Bit More Media with accurate account information; and/or",
          "(f)	your failure to keep your password or Account details secure and confidential.",
          "11.5.	Unless you notify us that you intend to make a claim in respect of an event within the notice period, then we shall have no liability for that event. The notice period for an event shall start on the day on you are aware, or ought reasonably to have become aware, of your grounds to make a claim in respect of the event and shall expire 12 months from that date. The notice must be in writing and must identify the event and the grounds for the claim in reasonable detail. ",
          "11.6.	Our maximum aggregate liability under or in connection with this EULA and your use of the App and Services whether in contract, tort (including negligence) or otherwise, shall in all circumstances be limited to the total £1,000,000.",
          "11.7.	Nothing in this EULA shall limit or exclude our liability for:",
          "(a)	death or personal injury resulting from our negligence;",
          "(b)	fraud or fraudulent misrepresentation; or",
          "(c)	any other liability that cannot be excluded or limited by English law.",
        ],
      },
      {
        header3: "12.	TERMINATION OF THIS EULA",
      },
      {
        list: [
          "12.1.	You may terminate this EULA at any time, by: ",
          "(a)	notifying Bit More Media; and ",
          "(b)	closing your Account. ",
          "12.2.	Without prejudice to our rights hereunder, we may terminate this EULA immediately without notice to you:",
          "(a)	if you commit a breach of this EULA which you fail to remedy (if remediable) within 5 days after the service of written notice requiring you to do so;",
          "(b)	if you breach any of the Licence Restrictions or the Acceptable Use Restrictions; ",
          "(c)	if we have any reason to suspect that your use of the App and Services is unlawful, or that it would bring us and/or other Users or the App into disrepute; ",
          "(d)	if we believe that it is required to terminate your use of the App and Services by law; or",
          "(e)	if we withdraw the App from service, or otherwise reorganise or restructure our business so as to necessitate the termination or suspension of provision of the App to you.",
          "12.3.	Without prejudice to our rights hereunder, we may terminate this EULA, or generally cease offering or deny access to the App and Services or any portion thereof, at any time for any or no reason whatsoever, immediately by notifying you in writing ",
          "12.4.	On termination for any reason:",
          "(a)	all rights granted to you under this EULA shall cease;",
          "(b)	you must immediately cease all activities authorised by this EULA, including your use of any Services; and",
          "(c)	you must immediately delete or remove the App from all Devices, and immediately destroy all copies of the App then in your possession, custody or control and certify to us that you have done so.",
        ],
      },
      {
        header3: "13.	COMMUNICATION BETWEEN US",
      },
      {
        list: [
          "13.1.	If you wish to contact us in writing, or if any condition in this EULA requires you to give us notice in writing, you can send this to us by e-mail to getintouch@bitmoremedia.com.",
          "13.2.	If we have to contact you or give you notice in writing, we will do so by e-mail, text message, short message service or by pre-paid post using the contact details you have provided to us.",
        ],
      },
      {
        header3: "14.	EVENTS OUTSIDE OUR CONTROL",
      },
      {
        list: [
          "14.1.	We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under this EULA that is caused by any act or event beyond our reasonable control, including, without limitation, failure of public or private telecommunications networks (each an “Event Outside Our Control”).",
          "14.2.	If an Event Outside Our Control takes place that affects the performance of our obligations under this EULA will be suspended and the time for performance of our obligations will be extended for the duration of the Event Outside Our Control. ",
          "14.3.	We shall take reasonable steps to prevent or minimise delay arising from an Event Outside Our Control. ",
        ],
      },
      {
        header3: "15.	OTHER IMPORTANT TERMS",
      },
      {
        list: [
          "15.1.	We may transfer our rights and obligations under this EULA to another organisation, but this will not affect your rights under this EULA.",
          "15.2.	You may only transfer your rights or obligations under this EULA to another person if we agree in writing.",
          "15.3.	This EULA does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this EULA. ",
          "15.4.	If we fail to insist that you perform any of your obligations under this EULA, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will automatically waive any later default by you.",
          "15.5.	Each of the conditions and provisions of this EULA operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect and Bit More Media shall notify its Users of any replacement conditions or provision which shall, where permitted by law, have retroactive effect. ",
          "15.6.	Please note that this EULA, and any documents referred to therein, its subject matter and its formation, are governed by the law of England and Wales. You and we both agree that the courts of England and Wales will have exclusive jurisdiction to settle any disputes (including non-contractual) arising from or in connection with this EULA.",
          "15.7.	The use of this App is lawful in England and Wales. Should you choose to use the App, anywhere else in the world you are responsible for checking local law and ensuring your compliance with it. ",
        ],
      },
    ],
  },
]
