import React from "react"

import SEO from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

import TermsAndConditions from "../../features/LegalStuff/TermsAndConditions"

const TermsAndConditionsPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="Terms and Conditions" />
      <TermsAndConditions />
    </PageWrapper>
  )
}

export default TermsAndConditionsPage
